<template>
  <div class="page">
    <!-- 引入的返回头组件 -->
    <titlebar :name="name" />
    <div class="search_box">
      <input
        type="text"
        class="search"
        placeholder="请输入商品条码/商品名搜索"
        v-model="param"
      />
      <!-- <input type="text" class="choose" placeholder="请输入操作人" v-model="s_time" /> -->
      <!-- <input type="text" class="choose" readonly="readonly" placeholder="请选择日期" /> -->
      <div class="choose" @click="operate">
        {{ operatevl || "请选择操作人" }}
        <!-- <img src="@/static/select.png" alt="" /> -->
      </div>
      <div class="choose" @click="time">
        {{ timevl || "请选择日期" }}
        <!-- <img src="@/static/select.png" alt="" /> -->
      </div>
      <div class="search_btn" @click="search()">搜索</div>
    </div>
    <div class="List_box bg_f8">
      <div class="list_name">商品名称</div>
      <div class="list_classify">商品分类</div>
      <div class="list_bar">商品条码</div>
      <div class="list_prc">变动类型</div>
      <div class="list_num">库存变动</div>
      <div class="list_people">操作人</div>
      <div class="list_time">创建时间</div>
    </div>
    <div class="list_page">
      <div class="List_box" v-for="(item, i) in StockOutlist" :key="i">
        <div class="list_name">{{ item.goods_name }}</div>
        <div class="list_classify">{{ item.goods_category }}</div>
        <div class="list_bar">{{ item.bar_code }}</div>
        <div class="list_prc" v-if="item.t == 1">销售出库</div>
        <div class="list_prc" v-else>其它</div>
        <div class="list_num">{{ item.stock_change }}</div>
        <div class="list_people">{{ item.staff }}</div>
        <div class="list_time">{{ item.create_time }}</div>
      </div>
    </div>
    <van-col span="12">
      <div style="width: fit-content; margin:3rem 0 3rem 8rem">
        <van-pagination
          v-model="page"
          :total-items="total"
          :items-per-page="limit"
          @change="pageChange"
          force-ellipses
        />
      </div>
    </van-col>
    <!-- 操作人选择 -->
    <van-popup v-model="showcz" position="bottom" :style="{ height: '40%' }">
      <van-picker
        title="操作人选择"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
        @change="onChange"
        value-key="staff"
      />
    </van-popup>
    <!-- 日期选择器 -->
    <van-popup v-model="show" position="bottom" :style="{ height: '40%' }">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="this.maxDate"
        @confirm="confirmFn()"
        @cancel="cancel()"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import titlebar from "@/views/component/titlebar";
export default {
  created() {
    this.name = this.$route.query.name; //拿到传过来的标题
    console.log("name:", this.name);
    this.getStockOutBound();
  },
  components: {
    titlebar: titlebar,
  },
  data() {
    return {
      page: 1, //页数
      limit: 8, //条数
      total: 0,
      name: "",
      value: "",
      show: false, // 时间弹窗
      timevl: "",
      minDate: new Date(2020, 10, 1),
      maxDate: new Date(2050, 10, 1),
      changeDate: new Date(),
      currentDate: new Date(),
      showcz: false, // 操作人选择
      columns: [
        { staff_id: 1, staff: "员工名称+员工工号" },
        { staff_id: 2, staff: "员名+员工号" },
      ],
      StockOutlist: [], //出库明细表
      operatevl: "", //操作人的名字
      operatevl_id: "", //操作人的ID
      param: "", //商品条码或商品名称
    };
  },
  methods: {
    // 筛选
    search() {
      var data = {
        shop_id: localStorage.getItem("shop_id"),
        limit: this.limit,
        page: this.page,
        s_time: this.timevl,
        staff_id: this.operatevl_id,
        param: this.param,
      };
      this.$api.stockOutBound(data).then((res) => {
        this.StockOutlist = res.data.list;
        this.total = res.data.count;
        this.columns = res.data.list;
      });
    },
    // 出库明细表
    getStockOutBound() {
      var data = {
        shop_id: localStorage.getItem("shop_id"),
        limit: this.limit,
        page: this.page,
      };
      this.$api.stockOutBound(data).then((res) => {
        this.StockOutlist = res.data.list;
        this.total = res.data.count;
        this.columns = res.data.list;
      });
    },
    // 页码改变
    pageChange(e) {
      this.page = e;
      console.log("销售商品列表，当前页码：", this.page);
      this.getList();
    },
    // 选择操作人弹窗
    operate() {
      this.showcz = true;
    },
    onChange(picker, value, index) {
      // Toast(`当前值：${value}, 当前索引：${index}`);
    },
    // 操作人确认
    onConfirm(e) {
      console.log("操作人确定===", e);
      this.operatevl = e.staff;
      this.operatevl_id = e.staff_id;
      this.showcz = false;
    },
    // 操作人取消
    onCancel() {
      this.showcz = false;
    },
    time() {
      this.show = true;
    },
    // 时间选择器:当值变化时触发的事件
    change() {
      this.changeDate = this.currentDate; // Tue Sep 08 2020 00:00:00 GMT+0800 (中国标准时间)
    },
    // 时间选择器完成
    confirmFn() {
      this.timevl = this.timeFormat(this.currentDate);
      this.show = false;
    },
    // 时间选择器取消
    cancel() {
      this.show = false;
    },
    timeFormat(time) {
      // 时间格式化 2019-09-08
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      return year + "-" + month + "-" + day;
    },
  },
  mounted() {
    this.timeFormat(new Date());
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 192rem;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  // align-items: center;

  .search_box {
    border-top: 0.1rem solid #e5e5e5;
    padding-top: 3rem;
    display: flex;
    // flex-direction: column;
    align-items: center;
    margin-left: 5.2rem;
    margin-bottom: 3.7rem;
    .search {
      width: 41.1rem;
      height: 8rem;
      background: #f8f8f8;
      border-radius: 0.8rem;
      font-size: 2.6rem;
      padding-left: 5rem;
      margin-right: 4rem;
    }
    .choose {
      width: 40rem;
      height: 8rem;
      display: flex;
      align-items: center;
      color: #666666;
      background: #f8f8f8;
      font-size: 2.6rem;
      border-radius: 0.8rem;
      padding-left: 4.1rem;
      margin-right: 4.1rem;
      position: relative;
      // margin-right: 16.4rem;

      img {
        width: 2.2rem;
        height: 1.4rem;
        position: absolute;
        right: 2.8rem;
      }
    }
    .search_btn {
      width: 19.4rem;
      height: 6rem;
      background: #1588F5;
      font-size: 2.6rem;
      font-weight: 400;
      color: #ffffff;
      line-height: 3.7rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 7.9rem;
    }
    .library {
      width: 17.7rem;
      height: 6rem;
      border: 0.1rem solid #e5e5e5;
      font-size: 2.6rem;
      font-weight: 400;
      line-height: 3.7rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 4.3rem;
    }
  }
  .list_page {
    // height: 67rem;
    flex: 1;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .List_box {
    // margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 192rem;
    height: 8rem;
    font-size: 2.6rem;
    font-weight: bold;
    color: #333333;
    border-bottom: 0.1rem solid #e5e5e5;
    // text-align: left;
    .list_name {
      width: 60rem;
      padding-top: 1.3rem;
      padding-bottom: 1.3rem;
      margin-left: 4rem;
      text-align: center;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
    }
    .list_classify {
      width: 28rem;
      padding-top: 1.3rem;
      padding-bottom: 1.3rem;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .list_bar {
      width: 28rem;
      padding-top: 1.3rem;
      padding-bottom: 1.3rem;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .list_prc {
      width: 18rem;
      padding-top: 1.3rem;
      padding-bottom: 1.3rem;
      text-align: center;
    }
    .list_num {
      width: 18rem;
      padding-top: 1.3rem;
      padding-bottom: 1.3rem;
      text-align: center;
    }
    .list_people {
      width: 28rem;
      padding-top: 1.3rem;
      padding-bottom: 1.3rem;
      text-align: center;
    }
    .list_time {
      width: 38rem;
      padding-top: 1.3rem;
      padding-bottom: 1.3rem;
      text-align: center;
    }
  }
  /deep/.van-picker__cancel {
    font-size: 2.6rem !important;
  }
  /deep/.van-picker__title {
    font-size: 2.6rem !important;
    height: 100%;
    display: flex;
    align-items: center;
  }
  /deep/.van-picker__confirm {
    font-size: 2.6rem !important;
    color: #1588F5;
  }
  /deep/.van-ellipsis {
    font-size: 2.6rem !important;
  }
  /deep/.van-pagination__item--active {
    color: #fff !important;
    background-color: #1588F5 !important;
  }
  /deep/.van-pagination__item {
    color: #1588F5;
  }
  .bg_f8 {
    background: #f8f8f8;
  }
}
</style>
